import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../config";

function Settings() {
  // USE-STATE HOOKS FOR MANAGING SERVICE MODEL
  const [action, setAction] = useState("create");

  const [preloader, setPreloader] = useState(false);
  const [validationErrors, setValidationErros] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  // Staff Inputs
  const [sitename, setSiteName] = useState(" ");
  const [sitePublisher, setSitePublisher] = useState(" ");
  const [about, setAbout] = useState(" ");
  const [email, setEmail] = useState(" ");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [address, setAddress] = useState("");
  const [logo, setLogo] = useState("");
  const [favicon, setFavicon] = useState("");

  const [password, setPassword] = useState(" ");

  const token = localStorage.getItem("token");

  /*
|========================================================
| USE-EFFECT HOOK FOR CALLING API
|========================================================
*/
  useEffect(() => {
    getSiteSettings();
    // getAllSubjects();
  }, []);

  /*
|========================================================
| Edit Site Settings API Calling
|========================================================
*/
  async function getSiteSettings(e) {
    let url = api_url + "settings/edit";

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    // console.log(response);

    if (response && response.status == 200) {
      setPreloader(false);

      // let data = response.data;
      let settings = response.data.settings;

      // set profile input values
      setSiteName(settings.site_name);
      setSitePublisher(settings.site_publisher);
      setAbout(settings.about);
      setEmail(settings.email);
      setMobile(settings.mobile_no);
      setPhone(settings.phone_no);
      setWhatsapp(settings.whatsapp_no);
      setAddress(settings.address);
    } else {
      console.log("something went wrong");
    }
  }

  /*
|========================================================
| Update site settings -- API CALLING
|========================================================
*/
  async function updateSettings(e) {
    e.preventDefault();

    console.log("form Submitted");
    setErrors("");
    setMessage("");

    // setValidationErros(false);
    setPreloader(true);

    // get token from local storage
    const token = localStorage.getItem("token");
    // console.log(token);

    // MAKE FORMDATA FOR CALLING STORE NEW SERVER API
    const formData = new FormData();
    formData.append("site_name", sitename);
    formData.append("site_publisher", sitePublisher);
    formData.append("about", about);
    formData.append("email", email);
    formData.append("mobile_no", mobile);
    formData.append("phone_no", phone);
    formData.append("whatsapp_no", whatsapp);
    formData.append("address", address);
    formData.append("logo", logo);
    formData.append("favicon", favicon);
    const body = formData;

    let url = api_url + "settings/update";

    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    // console.log(response);
    if (response && response.status == 200) {
      setPreloader(false);

      console.log(response.data);

      let data = response.data;
      if (data.status == 200) {
        let message = data.message;
        setMessage(message);
        setSuccessMessage(true);

        // navigate("/student/services");
      } else {
        setErrors(data.errors);
        setValidationErros(true);
      }
    } else {
      console.log("API CALLING FAILED");
    }
  }

  return (
    <>
      {!preloader ? (
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="page-header">
              <nav className="breadcrumb-one" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/settings">Settings</Link>
                  </li>
                </ol>
              </nav>
            </div>

            <div className="account-settings-container layout-top-spacing">
              <div className="account-content">
                <div
                  className="scrollspy-example"
                  data-spy="scroll"
                  data-target="#account-settings-scroll"
                  data-offset={-100}
                >
                  <div className="row">
                    <div className="col-md-8 mx-auto layout-spacing">
                      <div className="card">
                        <div className="card-header bg-white border-0">
                          <h4>Site Settings</h4>
                        </div>

                        <div className="card-body px-0 py-0">

                          {/* display alerts */}
                          {validationErrors ? (
                            <div
                              className="alert alert-danger alert-dismissible mx-4"
                              role="alert"
                            >
                              <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                              <ul className="mb-0">
                                {errors.map((error) => {
                                  return (
                                    <li key={error.id} className="list">
                                      <span className="repo-text">
                                        {error}{" "}
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          ) : (
                            <></>
                          )}
                          {message ? (
                            <div
                              className="alert alert-success alert-dismissible mx-4"
                              role="alert"
                            >
                              <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                              <h6 className="mb-0">{message}</h6>
                            </div>
                          ) : (
                            <></>
                          )}

                          {/* update form */}
                          <form onSubmit={updateSettings}>
                            <div className="modal-body px-4">
                              <div className="row">
                                <div className="col-lg-6 col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="siteName">
                                      Site Name <sup>*</sup>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mb-4"
                                      id="siteName"
                                      name="siteName"
                                      value={sitename}
                                      placeholder="Site Name"
                                      required
                                      onChange={(e) =>
                                        setSiteName(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="sitePublisher">
                                      Site Publisher <sup>*</sup>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mb-4"
                                      id="sitePublisher"
                                      name="sitePublisher"
                                      value={sitePublisher}
                                      placeholder="Site Publisher"
                                      required
                                      onChange={(e) =>
                                        setSitePublisher(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group">
                                    <label htmlFor="about">About</label>
                                    <textarea
                                      className="form-control mb-4"
                                      id="about"
                                      name="about"
                                      value={about}
                                      placeholder="About"
                                      onChange={(e) => setAbout(e.target.value)}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-3 col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="email">
                                      Email <sup>*</sup>
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control mb-4"
                                      id="email"
                                      name="email"
                                      value={email}
                                      placeholder="Email"
                                      required
                                      onChange={(e) => setEmail(e.target.value)}
                                    />
                                  </div>
                                </div>

                                {/* <div className="col-lg-3 col-md-6">
                                <div className="form-group">
                                  <label htmlFor="password">
                                    Password <sup>*</sup>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control mb-4"
                                    id="password"
                                    name="password"
                                    value={password}
                                    placeholder="Password"
                                    required
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                </div>
                              </div> */}

                                <div className="col-lg-3 col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="mobile">
                                      Mobile No <sup>*</sup>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mb-4"
                                      id="mobile"
                                      name="mobile"
                                      value={mobile}
                                      placeholder="Mobile No"
                                      required
                                      onChange={(e) =>
                                        setMobile(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-3 col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="phone">
                                      Phone No <sup>*</sup>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mb-4"
                                      id="phone"
                                      name="phone"
                                      value={phone}
                                      placeholder="Phone No"
                                      required
                                      onChange={(e) => setPhone(e.target.value)}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-3 col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="whatsapp">
                                      Whatsapp No <sup>*</sup>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mb-4"
                                      id="whatsapp"
                                      name="whatsapp"
                                      value={whatsapp}
                                      placeholder="Whatsapp No"
                                      required
                                      onChange={(e) =>
                                        setWhatsapp(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="form-group mb-0">
                                    <label htmlFor="address">Address</label>
                                    <textarea
                                      className="form-control mb-0"
                                      id="address"
                                      name="address"
                                      value={address}
                                      placeholder="Address"
                                      onChange={(e) =>
                                        setAddress(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer border-0">
                              <button type="submit" className="btn btn-primary">
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <img
          className="d-flex mx-auto"
          style={{ marginTop: "125px" }}
          src={require("../../../src/assets/preloaders/loader2.gif")}
          width="50%"
          height="auto"
        />
      )}
    </>
  );
}

export default Settings;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../config";

function StatusModal({ updateStatuses }) {
  // USE-STATE HOOKS FOR MANAGING SERVICE MODEL
  const [action, setAction] = useState("create");
  const [serverError, setServerError] = useState(false);
  const [preloader, setPreloader] = useState(false);
  const [validationErrors, setValidationErros] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  // Staff Inputs
  const [title, setTitle] = useState(" ");
  const [visibility, setVisibility] = useState(" ");

  /*
|========================================================
| Update site settings -- API CALLING
|========================================================
*/
  async function storeStatus(e) {
    e.preventDefault();

    setErrors("");
    setMessage("");

    setPreloader(true);

    // get token from local storage
    const token = localStorage.getItem("token");
    // console.log(token);

    // MAKE FORMDATA FOR CALLING STORE NEW SERVER API
    const formData = new FormData();
    formData.append("title", title);
    formData.append("status", visibility);
    const body = formData;

    let url = api_url + "status/store";

    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    // console.log(response);
    if (response && response.status == 200) {
      setPreloader(false);

      console.log(response.data);

      let data = response.data;
      if (data.status == 200) {
        updateStatuses(true);
        let message = data.message;
        setMessage(message);
        setSuccessMessage(true);

        // navigate("/student/services");
      } else {
        setErrors(data.errors);
        setValidationErros(true);
      }
    } else {
      console.log("API CALLING FAILED");
    }
  }

  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Status
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
            </div>

            <div className="modal-body py-0">
              {/* display alerts */}
              {validationErrors ? (
                <div
                  className="alert alert-danger alert-dismissible m-2"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <ul className="mb-0">
                    {errors.map((error) => {
                      return (
                        <li key={error.id} className="list">
                          <span className="repo-text">{error} </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <></>
              )}
              {message ? (
                <div
                  className="alert alert-success alert-dismissible m-2"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h6 className="mb-0">{message}</h6>
                </div>
              ) : (
                <></>
              )}
            </div>

            {/* status form */}
            <form onSubmit={storeStatus}>
              <div className="modal-body p-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="title">
                        Title <sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="title"
                        name="title"
                        value={title}
                        placeholder="Title"
                        required
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="visibility">Visibility</label>
                      <select
                        className="form-control"
                        id="visibility"
                        name="visibility"
                        value={visibility}
                        onChange={(e) => setVisibility(e.target.value)}
                      >
                        <option value="">Set Visibility</option>
                        <option value="1">Active</option>
                        <option value="0">In Active</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn" data-dismiss="modal">
                  <i className="flaticon-cancel-12" />
                  Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default StatusModal;

import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
return (
<>
    {/* BEGIN NAVBAR */}
    <div className="header-container">
        <header className="header navbar navbar-expand-sm">
            <a href="javascript:void(0);" className="sidebarCollapse" data-placement="bottom"><svg
                    xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
                    className="feather feather-menu">
                    <line x1={3} y1={12} x2={21} y2={12} />
                    <line x1={3} y1={6} x2={21} y2={6} />
                    <line x1={3} y1={18} x2={21} y2={18} />
                </svg></a>
            <div className="nav-logo align-self-center">
                <Link className="navbar-brand" to="/dashboard">
                <img alt="logo" src="/favicon.png" /> <strong className='text-white'>Box Print</strong>
                </Link>
            </div>
            <ul className="navbar-item flex-row mr-auto">
                <li className="nav-item align-self-center search-animated">
                    <form className="form-inline search-full form-inline search" role="search">
                        <div className="search-bar">
                            {/* <input type="text" className="form-control search-form-control  ml-lg-auto"
                                placeholder="Search..." /> */}
                        </div>
                    </form>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
                        className="feather feather-search toggle-search">
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                    </svg> */}
                </li>
            </ul>
            <ul className="navbar-item flex-row nav-dropdowns">
                <li className="nav-item dropdown user-profile-dropdown order-lg-0 order-1">
                    <a href="javascript:void(0);" className="nav-link dropdown-toggle user"
                        id="user-profile-dropdown" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <div className="media">
                            <div className="media-body align-self-center">
                                <h6>Andy King</h6>
                                <p>Manager</p>
                            </div>
                            <img src="assets/img/90x90.jpg" className="img-fluid" alt="admin-profile" />
                            <span className="badge badge-success" />
                        </div>
                    </a>
                    <div className="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">
                        <div className="dropdown-item">
                            <Link to="/profile">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-user">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                    <circle cx={12} cy={7} r={4} />
                                </svg>
                                <span> Profile</span>
                            </Link>
                        </div>
                        <div className="dropdown-item">
                            <Link to="/settings">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-inbox">
                                    <polyline points="22 12 16 12 14 15 10 15 8 12 2 12" />
                                    <path
                                        d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />
                                </svg> 
                                <span> Settings</span>
                            </Link>
                        </div>
                        <div className="dropdown-item">
                            <Link to="/logout">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-log-out">
                                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                                    <polyline points="16 17 21 12 16 7" />
                                    <line x1={21} y1={12} x2={9} y2={12} />
                                </svg> <span>Log Out</span>
                            </Link>
                        </div>
                    </div>
                </li>
            </ul>
        </header>
    </div>
    {/* END NAVBAR */}

</>
)
}

export default Header

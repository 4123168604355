import React from 'react'

function StatCard({title, value}) {
return (
<>
    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 mb-2">
        <div className="widget widget-chart-two">
            <div className="widget-heading">
                <h5 className="text-uppercase">{title}</h5>
                <h3 className='mb-0'><b>{value}</b></h3>
            </div>
        </div>
    </div>
</>
)
}

export default StatCard

import { Link } from "react-router-dom";
import StatCard from "../../components/Card/Stat/StatCard";
import LeadModal from "../../components/Modal/LeadModal";

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../config";
import TaskModal from "../../components/Modal/TaskModal";

function Todo() {
  // USE USE-STATE HOOKS TO MANAGE PAGE CONTENT
  const [actor, setActor] = useState(" ");
  let user = JSON.parse(localStorage.getItem("user"));

  const [preloader, setPreloader] = useState(true);
  const [leads, setLeads] = useState([]);
  const [stats, setStats] = useState([]);

  const location = useLocation();
  const path = location.pathname;
  // console.log(path);

  const token = localStorage.getItem("token");

  useEffect(() => {
    getStaff();

    const role = localStorage.getItem("role");
    setActor(role);
  }, []);

  // get student services
  async function getStaff(e) {
    let url = api_url + "leads";

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    // console.log(response);
    if (response && response.status == 200) {
      setPreloader(false);

      let leads = response.data.leads;
      let data = response.data;

      //   console.log(data);
      setLeads(leads);
      setStats(data);
    } else {
      console.log("something went wrong");
    }
  }

  return (
    <>
      {!preloader ? (
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="page-header mb-3">
              <nav className="breadcrumb-one" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/todo">Todo</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    List
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row layout-spacing">
              <div className="col-lg-12">
                <div className="statbox widget box box-shadow">
                  <div className="widget-content widget-content-area">
                    <h4 className="mb-3">
                      <strong>Tasks to do</strong>
                    </h4>

                    {/* filters */}
                    <div className="row bg-light-dark pt-3 mx-0 mb-3">
                      <div className="col-lg-3 col-md-6">
                        <div className="form-group">
                          <label htmlFor="siteName">
                            Task Type <sup>*</sup>
                          </label>
                          <select className="form-control" id="type">
                            <option>Please Select</option>
                            <option value="meetings">Do Nothing</option>
                            <option value="calls">Contact Client</option>
                            <option value="emails">Follow Up</option>
                            <option value="sales">Recive Token Payment</option>
                            <option value="sales">Meet Client</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="form-group">
                          <label htmlFor="siteName">
                            Show Tasks <sup>*</sup>
                          </label>
                          <select className="form-control" id="type">
                            <option>Please Select</option>
                            <option value="meetings">My Tasks</option>
                            <option value="calls">Other Option</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="form-group">
                          <label htmlFor="phone">
                            Deadline <sup>*</sup>
                          </label>
                          <input
                            type="date"
                            className="form-control mb-4"
                            id="deadline"
                            name="deadline"
                            placeholder="Deadline"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div className="row mx-0">
                      <ul className="list-unstyled  mb-0">
                        <li
                          className="menu single-menu d-inline-block"
                          style={{
                            padding: "10px 20px",
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          <Link to="/dashboard">Overdue (102)</Link>
                        </li>
                        <li
                          className="menu single-menu d-inline-block"
                          style={{
                            padding: "10px 20px",
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          <Link to="/dashboard">Today (15)</Link>
                        </li>
                        <li
                          className="menu single-menu d-inline-block"
                          style={{
                            padding: "10px 20px",
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          <Link to="/dashboard">Tomorrow (25)</Link>
                        </li>
                        <li
                          className="menu single-menu d-inline-block"
                          style={{
                            padding: "10px 20px",
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          <Link to="/dashboard">7 Days (50)</Link>
                        </li>
                        <li
                          className="menu single-menu d-inline-block"
                          style={{
                            padding: "10px 20px",
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          <Link to="/dashboard">All (202)</Link>
                        </li>
                      </ul>
                    </div>
                    <hr />

                    <table id="style-3" className="table style-3  table-hover">
                      <thead>
                        <tr>
                          <th width="15%">Task</th>
                          <th width="15%">Assigned To</th>
                          <th width="15%">Deadline</th>
                          <th width="10%">Client</th>
                          <th width="10%">Id</th>
                          <th width="10%">Allocated To</th>
                          <th width="10%">Interest</th>
                          <th width="5%" className="text-center">
                            Status
                          </th>
                          <th width="5%" className="text-center dt-no-sorting">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {leads.map((lead, index) => {
                          return (
                            <tr key={lead.id}>
                              <td className="checkbox-column">
                                {index % 2 == 0
                                  ? "Follow Up"
                                  : "Arrage Meeting"}
                              </td>
                              <td>
                                {index % 2 == 0
                                  ? "Kashif Aslam"
                                  : "Kumail Abbas"}
                              </td>
                              <td>
                                {lead.created_at}
                              </td>
                              <td>
                              {lead.name}
                              </td>
                              <td>{lead.source_description}</td>
                              <td>
                              {index % 2 == 0
                                  ? "Zohaib Sadiq"
                                  : "Faizan Kaleem"}
                              </td>
                              <td>{lead.project.title}</td>

                              <td className="text-center">
                                <span className="shadow-none badge badge-info">
                                  {lead.status.title}
                                </span>
                              </td>
                              <td className="text-center">
                                <ul className="table-controls">
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-placement="top"
                                      title
                                      data-original-title="Add"
                                      data-toggle="modal"
                                      data-target=".addTask"
                                    >
                                      <svg
                                        clip-rule="evenodd"
                                        fill-rule="evenodd"
                                        stroke-linejoin="round"
                                        stroke-miterlimit="2"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="m12.002 2c5.518 0 9.998 4.48 9.998 9.998 0 5.517-4.48 9.997-9.998 9.997-5.517 0-9.997-4.48-9.997-9.997 0-5.518 4.48-9.998 9.997-9.998zm0 1.5c-4.69 0-8.497 3.808-8.497 8.498s3.807 8.497 8.497 8.497 8.498-3.807 8.498-8.497-3.808-8.498-8.498-8.498zm-.747 7.75h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z"
                                          fill-rule="nonzero"
                                        />
                                      </svg>
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title
                                      data-original-title="Edit"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-edit-2 p-1 br-6 mb-1"
                                      >
                                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                      </svg>
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title
                                      data-original-title="Delete"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-trash p-1 br-6 mb-1"
                                      >
                                        <polyline points="3 6 5 6 21 6" />
                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                      </svg>
                                    </a>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <img
          className="d-flex mx-auto"
          style={{ marginTop: "125px" }}
          src={require("../../../src/assets/preloaders/loader2.gif")}
          width="50%"
          height="auto"
        />
      )}

      <LeadModal />
      <TaskModal />
    </>
  );
}

export default Todo;

import React from 'react'

function LeadModal() {
return (
<>
    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add Lead Information</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        x
                    </button>
                </div>
                <div className="modal-body p-4">
                    <div className="row">

                        <h6 className='col-12'>GENERAL INFORMATION</h6>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="project_id">Project</label>
                                <select className="form-control" id="project_id">
                                    <option>Assign Project</option>
                                    <option value="male">Male</option>
                                    <option value="female">Femail</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="user_id">Staff</label>
                                <select className="form-control" id="user_id">
                                    <option>Assign Staff</option>
                                    <option value="male">Male</option>
                                    <option value="female">Femail</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="status_id">Status</label>
                                <select className="form-control" id="status_id">
                                    <option>Set Lead Status</option>
                                    <option value="male">Male</option>
                                    <option value="female">Femail</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>

                        <h6 className='col-12 pt-3'>CUSTOMER INFORMATION</h6>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="name">Name <sup>*</sup></label>
                                <input type="text" className="form-control mb-4" id="name" placeholder="Name"
                                    required />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="email">Email <sup>*</sup></label>
                                <input type="email" className="form-control mb-4" id="email" placeholder="Email"
                                    required />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="cell_1">Cell # 1 <sup>*</sup></label>
                                <input type="text" className="form-control mb-4" id="cell_1" placeholder="Cell 1"
                                    required />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="cell_2">Cell # 2 <sup>*</sup></label>
                                <input type="text" className="form-control mb-4" id="cell_2" placeholder="Cell 2"
                                    required />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="city">City</label>
                                <input type="text" className="form-control mb-4" id="city" placeholder="City" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="locality">Locality</label>
                                <input type="text" className="form-control mb-4" id="locality"
                                    placeholder="Locality" />
                            </div>
                        </div>

                        <hr />
                        <h6 className='col-12 pt-3'>SOURCE INFORMATION</h6>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="source">Source</label>
                                <input type="text" className="form-control mb-4" id="source"
                                    placeholder="Source" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="source_description">Source Detail</label>
                                <input type="text" className="form-control mb-4" id="source_description" placeholder="Detail About Source" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn" data-dismiss="modal"><i className="flaticon-cancel-12" />
                        Discard</button>
                    <button type="button" className="btn btn-primary">Save</button>
                </div>
            </div>
        </div>
    </div>

</>
)
}

export default LeadModal

import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import SignIn from "./components/Auth/Signin/SignIn";

import Header from "./components/Master/Header/Header";
import Topbar from "./components/Master/Topbar/Topbar";
import Footer from "./components/Master/Footer/Footer";

import Dashboard from "./Pages/Dashboard/Dashboard";
import Settings from "./Pages/Setting/Settings";
import Staff from "./Pages/Staff/Staff";
import Project from "./Pages/Project/Project";
import Lead from "./Pages/Lead/Lead";
import Status from "./Pages/Status/Status";
import ForgetPassword from "./components/Auth/ForgetPassword/ForgetPassword";
import Logout from "./components/Auth/Logout/SignOut";
import Todo from "./Pages/Todo/Todo";

function App() {
        
const [isLogin, setIsLogin] = useState(localStorage.getItem("isLogin"));

return (
<>
      <BrowserRouter>
        {isLogin ? (
          <>
            <Header />
            <Topbar />
          </>
        ) : null}

        <Routes>
          <Route>
            {/* AUTH ROUTES */}
            <Route path="/login" element={<SignIn />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/logout" element={<Logout />} />

            {/* MODULES */}
            {isLogin ? (
              <>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/staff" element={<Staff />} />
                <Route path="/projects" element={<Project />} />
                <Route path="/leads" element={<Lead />} />
                <Route path="/Todo" element={<Todo />} />
                <Route path="/tasks" element={<Dashboard />} />
                <Route path="/statuses" element={<Status />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/test" element={<Dashboard />} />
              </>
            ) : null}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
);
}

export default App;
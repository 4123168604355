import React from 'react'
import Datatable from '../../components/Table/Datatable'
import { Link } from 'react-router-dom'
import StatCard from '../../components/Card/Stat/StatCard'
import ProjectModal from '../../components/Modal/ProjectModal'

function Project() {
return (
<>
    <div id="content" className="main-content">
        <div className="layout-px-spacing">
            <div className="page-header">
                <nav className="breadcrumb-one" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/projects">Projects</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">List</li>
                    </ol>
                </nav>
            </div>

            <div className="row layout-spacing layout-top-spacing">
              <StatCard title="Total Projects" value={100} />
              <StatCard title="Active Projects" value={37} />
              <StatCard title="Inactive Projects" value={63} />
            </div>

            <div className="row layout-spacing">
                <div className="col-lg-12">
                    <div className="statbox widget box box-shadow">
                        <div className="widget-content widget-content-area">
                            <h4>PROJECTS
                                <button type="button" class="btn btn-primary mb-2 mr-2 float-right" data-toggle="modal"
                                    data-target="#exampleModal">
                                    Add Project
                                </button>
                            </h4>
                            <hr />

                            <Datatable />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ProjectModal />

</>
)
}

export default Project

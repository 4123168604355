import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../Signin/signin.css";
import { api_url } from "../../../config";

function ForgetPassword() {

  // useState hooks for handling login form inputs data
  const [email, setUserName] = useState(" ");
  const [preloader, setPreloader] = useState(false);
  const [validationErrors, setValidationErros] = useState(false);
  const [errors, setErrors] = useState([]);

  // useNavigate hook for redirect after login
  const navigate = useNavigate();


  // login form submit event handler
  const loginEmail = async (e) => {
    e.preventDefault();

    // const token = localStorage.getItem("token");
    // // console.log(token);

    const formData = new FormData();
    formData.append("email", email);

    let url = api_url + "auth/login";
    const body = formData;

    const response = await axios.post(
      url,
      body,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );

    // console.log(response);
    // return;

    if (response && response.status == 200) {
      setPreloader(false);

      const data = response.data;
      if (data.status == 200) {
        // console.log("Successfully Logged In");

        let token = data.token;
        let user = data.user;

        // set role in localstorage
        let role = user.role_id == 2 ? "tutor" : "student";
        localStorage.setItem('role', role);

        // STORE DATA IN LOCAL STORAGE
        localStorage.setItem('isLogin', true);
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));

        // STORE DATA IN SESSION STORAGE
        // sessionStorage.setItem('isLogin', true);
        // sessionStorage.setItem('token', token);
        // sessionStorage.setItem('userName', user.name);
        // sessionStorage.setItem('user', user);

        if (user.role_id == 2) {
          navigate("/tutor/profile");
        } else {
          navigate("/student/profile");
        }

      } else {
        setErrors(data.errors);
        setValidationErros(true);
      }

    } else {
      console.log("API CALLING FAILED");
    }
  };

  return (
    <>
      {
        !preloader ?
          <section className="signin-form py-5">
            <div className="container">
              <div className="row">

                <div className="col-12 col-md-6 mx-auto">
                  <div
                    className="card signin-card"
                    style={{ borderRadius: "1rem" }}
                  >
                    <div className="card-header bg-white border-0 pt-5">
                      <img
                        src="/logo.svg"
                        className="w-50 mx-auto d-block"
                      />
                    </div>

                    <div className="card-body p-4 p-lg-5 text-black">

                      {
                        validationErrors ?
                          <div className="alert alert-danger alert-dismissible" role="alert">
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <ul className="mb-0">
                              {errors.map((error) => {
                                return (
                                  <li key={error.id} className='list'>
                                    <span className='repo-text'>{error} </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          :
                          <></>
                      }
                      <form onSubmit={loginEmail}>
                        <div className="d-flex align-items-center mb-3 pb-0">
                          <span className="mb-2">
                          Please enter the email associated with your account to receive a temporary password.
                          </span>
                        </div>

                        <div className="mb-2">
                          <input
                            type="email"
                            name="userName"
                            placeholder="Email"
                            id="userName"
                            required
                            onChange={(e) => setUserName(e.target.value)}
                            className="form-control 
                            "
                          />
                        </div>

                        <div className="pt-1 mb-2">
                          <button className="btn btn-dark border-0 w-100" style={{ backgroundColor: "#0D1A59" }}>
                            Submit
                          </button>
                        </div>

                        <p
                          className="mt-3"
                          style={{ color: "#393f81" }}
                        >
                          Go back to login screen{" "}
                          <Link
                            to="/login"
                            style={{ color: "#393f81" }}
                          >
                            <b>Login</b>
                          </Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          :

          <img src={require('../../../assets/preloaders/loader2.gif')} width="100%" height="auto" />
      }
    </>
  );
}

export default ForgetPassword;

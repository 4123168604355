import React from "react";

function TaskModal() {
  return (
    <>
      <div
        className="modal fade addTask"
        id=""
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Task
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
            </div>

            <div className="modal-body p-4">
              <h6 className="bg-light-dark text-dark p-3 mb-3">Current Task</h6>
              <div className="form-group row mb-2">
                <div className="col-md-3">
                  <label htmlFor="type" className="mt-2">
                    Type <sup>*</sup>
                  </label>
                </div>
                <div className="col-md-9">
                  <select className="form-control" id="type">
                    <option>Please Select</option>
                    <option value="meetings">Meetings</option>
                    <option value="calls">Calls</option>
                    <option value="emails">Emails</option>
                    <option value="sales">Sales</option>
                  </select>
                </div>
              </div>

              <div className="form-group row mb-2">
                <div className="col-md-3">
                  <label htmlFor="subType" className="mt-2">
                    Sub Type <sup>*</sup>
                  </label>
                </div>
                <div className="col-md-9">
                  <select className="form-control" id="subType">
                    <option>Please Select</option>
                    <option value="proposal">Proposal</option>
                    <option value="contacted-client">Contacted Client</option>
                    <option value="followed-up">Followed Up</option>
                  </select>
                </div>
              </div>

              <div className="form-group row mb-2">
                <label
                  htmlFor="completedDate"
                  className="col-xl-3 col-sm-3 col-sm-2 col-form-label"
                >
                  Completed Date <sup>*</sup>
                </label>
                <div className="col-xl-9 col-lg-9 col-sm-10">
                  <input
                    type="date"
                    className="form-control"
                    id="completedDate"
                    placeholder
                  />
                </div>
              </div>

              <div className="form-group row mb-2">
                <label
                  htmlFor="attachment"
                  className="col-xl-3 col-sm-3 col-sm-2 col-form-label"
                >
                  Attachment
                </label>
                <div className="col-xl-9 col-lg-9 col-sm-10">
                  <input
                    type="file"
                    className="form-control"
                    id="attachment"
                    placeholder
                  />
                </div>
              </div>

              <div className="form-group row mb-2">
                <label
                  htmlFor="comment"
                  className="col-xl-3 col-sm-3 col-sm-2 col-form-label"
                >
                  Comment
                </label>
                <div className="col-xl-9 col-lg-9 col-sm-10">
                  <textarea
                    type="text"
                    className="form-control mb-4"
                    id="comment"
                    placeholder="Comment"
                  />
                </div>
              </div>

              <h6 className="bg-light-dark text-dark p-3 mb-3">Next Task</h6>

              <div className="form-group row mb-2">
                <div className="col-md-3">
                  <label htmlFor="type" className="mt-2">
                    Next Task <sup>*</sup>
                  </label>
                </div>
                <div className="col-md-9">
                  <select className="form-control" id="type">
                    <option>Please Select</option>
                    <option value="meetings">Do Nothing</option>
                    <option value="calls">Contact Client</option>
                    <option value="emails">Follow Up</option>
                    <option value="sales">Recive Token Payment</option>
                    <option value="sales">Meet Client</option>
                  </select>
                </div>
              </div>

              <div className="form-group row mb-2">
                <div className="col-md-3">
                  <label htmlFor="type" className="mt-2">
                    Project <sup>*</sup>
                  </label>
                </div>
                <div className="col-md-9">
                  <select className="form-control" id="type">
                    <option>Please Select</option>
                    <option value="meetings">Project 1</option>
                    <option value="calls">Project 2</option>
                    <option value="emails">Project 3</option>
                    <option value="sales">Project 4</option>
                    <option value="sales">Project 5</option>
                  </select>
                </div>
              </div>

              <div className="form-group row mb-2">
                <label
                  htmlFor="completedDate"
                  className="col-xl-3 col-sm-3 col-sm-2 col-form-label"
                >
                  Deadline <sup>*</sup>
                </label>
                <div className="col-xl-9 col-lg-9 col-sm-10">
                  <input
                    type="date"
                    className="form-control"
                    id="completedDate"
                    placeholder
                  />
                </div>
              </div>

              <div className="form-group row mb-2">
                <div className="col-md-3">
                  <label htmlFor="type" className="mt-2">
                    Classification <sup>*</sup>
                  </label>
                </div>
                <div className="col-md-9">
                  <select className="form-control" id="type">
                    <option>Please Select</option>
                    <option value="calls">Urgent</option>
                    <option value="meetings">Moderate</option>
                    <option value="emails">Low</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn" data-dismiss="modal">
                <i className="flaticon-cancel-12" />
                Discard
              </button>
              <button type="button" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaskModal;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./signout.css";
import { api_url } from "../../../config";

function SignOut() {
  // USE-STATE HOOKS FOR MANAGING PASSWORD COMPONENT
  const [preloader, setPreloader] = useState(false);

  // useNavigate hook for redirect after login
  const navigate = useNavigate();

  useEffect(() => {
    logoutUser();
  }, []);

  /*
  |========================================================
  | LOGOUT USER -- API CALLING
  |========================================================
  */
  async function logoutUser() {
    setPreloader(true);

    // get token from local storage
    const token = localStorage.getItem("token");
    // console.log(token);
    // return;

    const body = "";

    //  END-POINT
    let url = api_url + "auth/logout";

    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    // console.log(response);
    if (response && response.status == 200) {

        localStorage.clear();
        // setIsLogin(false);

      setPreloader(false);
      navigate("/login");
    } else {
      console.log("API CALLING FAILED");
    }
  }
  return (
    <>
      {!preloader ? (
        <div className="container py-5">
          <div className="row">
            <div className="col-md-3 mx-auto">Logged Out Successfully</div>
          </div>
        </div>
      ) : (
        <img
          className="d-flex mx-auto"
          style={{ marginTop: "125px" }}
          src={require("../../../assets/preloaders/loader2.gif")}
          width="50%"
          height="auto"
        />
      )}
    </>
  );
}

export default SignOut;

import React from 'react'

function Datatable() {
return (
<>
    <table id="style-3" className="table style-3  table-hover">
        <thead>
            <tr>
                <th className="checkbox-column text-center"> Record Id </th>
                <th className="text-center">Image</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Mobile No.</th>
                <th className="text-center">Status</th>
                <th className="text-center dt-no-sorting">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className="checkbox-column text-center"> 1 </td>
                <td className="text-center">
                    <span><img src="assets/img/90x90.jpg" className="profile-img" alt="avatar" /></span>
                </td>
                <td>Donna</td>
                <td>Rogers</td>
                <td>donna@yahoo.com</td>
                <td>555-555-5555</td>
                <td className="text-center"><span className="shadow-none badge badge-primary">Approved</span></td>
                <td className="text-center">
                    <ul className="table-controls">
                        <li>
                            <a href="javascript:void(0);" className="bs-tooltip" data-toggle="tooltip"
                                data-placement="top" title data-original-title="Edit"><svg
                                    xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-edit-2 p-1 br-6 mb-1">
                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" className="bs-tooltip" data-toggle="tooltip"
                                data-placement="top" title data-original-title="Delete"><svg
                                    xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-trash p-1 br-6 mb-1">
                                    <polyline points="3 6 5 6 21 6" />
                                    <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                </svg>
                            </a>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td className="checkbox-column text-center"> 2 </td>
                <td className="text-center">
                    <span><img src="assets/img/90x90.jpg" className="profile-img" alt="avatar" /></span>
                </td>
                <td>Andy</td>
                <td>King</td>
                <td>andyking@gmail.com</td>
                <td>555-555-6666</td>
                <td className="text-center"><span className="shadow-none badge badge-warning">Suspended</span></td>
                <td className="text-center">
                    <ul className="table-controls">
                        <li><a href="javascript:void(0);" className="bs-tooltip" data-toggle="tooltip"
                                data-placement="top" title data-original-title="Edit"><svg
                                    xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-edit-2 p-1 br-6 mb-1">
                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                </svg></a></li>
                        <li><a href="javascript:void(0);" className="bs-tooltip" data-toggle="tooltip"
                                data-placement="top" title data-original-title="Delete"><svg
                                    xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-trash p-1 br-6 mb-1">
                                    <polyline points="3 6 5 6 21 6" />
                                    <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                </svg></a></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td className="checkbox-column text-center"> 3 </td>
                <td className="text-center">
                    <span><img src="assets/img/90x90.jpg" className="profile-img" alt="avatar" /></span>
                </td>
                <td>Alma</td>
                <td>Clarke</td>
                <td>Alma@live.com</td>
                <td>777-555-5555</td>
                <td className="text-center"><span className="shadow-none badge badge-danger">Closed</span></td>
                <td className="text-center">
                    <ul className="table-controls">
                        <li><a href="javascript:void(0);" className="bs-tooltip" data-toggle="tooltip"
                                data-placement="top" title data-original-title="Edit"><svg
                                    xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-edit-2 p-1 br-6 mb-1">
                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                </svg></a></li>
                        <li><a href="javascript:void(0);" className="bs-tooltip" data-toggle="tooltip"
                                data-placement="top" title data-original-title="Delete"><svg
                                    xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-trash p-1 br-6 mb-1">
                                    <polyline points="3 6 5 6 21 6" />
                                    <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                </svg></a></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td className="checkbox-column text-center"> 4 </td>
                <td className="text-center">
                    <span><img src="assets/img/90x90.jpg" className="profile-img" alt="avatar" /></span>
                </td>
                <td>Vincent</td>
                <td>Carpenter</td>
                <td>vinnyc@outlook.com</td>
                <td>555-666-5555</td>
                <td className="text-center"><span className="shadow-none badge badge-primary">Approved</span></td>
                <td className="text-center">
                    <ul className="table-controls">
                        <li><a href="javascript:void(0);" className="bs-tooltip" data-toggle="tooltip"
                                data-placement="top" title data-original-title="Edit"><svg
                                    xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-edit-2 p-1 br-6 mb-1">
                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                </svg></a></li>
                        <li><a href="javascript:void(0);" className="bs-tooltip" data-toggle="tooltip"
                                data-placement="top" title data-original-title="Delete"><svg
                                    xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-trash p-1 br-6 mb-1">
                                    <polyline points="3 6 5 6 21 6" />
                                    <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                </svg></a></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td className="checkbox-column text-center"> 5 </td>
                <td className="text-center">
                    <span><img src="assets/img/90x90.jpg" className="profile-img" alt="avatar" /></span>
                </td>
                <td>Kristen</td>
                <td>Beck</td>
                <td>kristen@adobe.com</td>
                <td>444-444-4444</td>
                <td className="text-center"><span className="shadow-none badge badge-warning">Suspended</span></td>
                <td className="text-center">
                    <ul className="table-controls">
                        <li><a href="javascript:void(0);" className="bs-tooltip" data-toggle="tooltip"
                                data-placement="top" title data-original-title="Edit"><svg
                                    xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-edit-2 p-1 br-6 mb-1">
                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                </svg></a></li>
                        <li><a href="javascript:void(0);" className="bs-tooltip" data-toggle="tooltip"
                                data-placement="top" title data-original-title="Delete"><svg
                                    xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-trash p-1 br-6 mb-1">
                                    <polyline points="3 6 5 6 21 6" />
                                    <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                </svg></a></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td className="checkbox-column text-center"> 6 </td>
                <td className="text-center">
                    <span><img src="assets/img/90x90.jpg" className="profile-img" alt="avatar" /></span>
                </td>
                <td>Oscar</td>
                <td>Garner</td>
                <td>oscar@gmail.com</td>
                <td>111-111-1111</td>
                <td className="text-center"><span className="shadow-none badge badge-danger">Closed</span></td>
                <td className="text-center">
                    <ul className="table-controls">
                        <li><a href="javascript:void(0);" className="bs-tooltip" data-toggle="tooltip"
                                data-placement="top" title data-original-title="Edit"><svg
                                    xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-edit-2 p-1 br-6 mb-1">
                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                </svg></a></li>
                        <li><a href="javascript:void(0);" className="bs-tooltip" data-toggle="tooltip"
                                data-placement="top" title data-original-title="Delete"><svg
                                    xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-trash p-1 br-6 mb-1">
                                    <polyline points="3 6 5 6 21 6" />
                                    <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                </svg></a></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td className="checkbox-column text-center"> 7 </td>
                <td className="text-center">
                    <span><img src="assets/img/90x90.jpg" className="profile-img" alt="avatar" /></span>
                </td>
                <td>Nia</td>
                <td>Hillyer</td>
                <td>niaHill@yahoo.com</td>
                <td>111-666-1111</td>
                <td className="text-center"><span className="shadow-none badge badge-primary">Approved</span></td>
                <td className="text-center">
                    <ul className="table-controls">
                        <li>
                            <a href="javascript:void(0);" className="bs-tooltip" data-toggle="tooltip"
                                data-placement="top" title data-original-title="Edit"><svg
                                    xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-edit-2 p-1 br-6 mb-1">
                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" className="bs-tooltip" data-toggle="tooltip"
                                data-placement="top" title data-original-title="Delete"><svg
                                    xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                    strokeLinejoin="round" className="feather feather-trash p-1 br-6 mb-1">
                                    <polyline points="3 6 5 6 21 6" />
                                    <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                </svg>
                            </a>
                        </li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
</>
)
}

export default Datatable

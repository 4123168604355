import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../config";

function StaffModal() {
  // USE-STATE HOOKS FOR MANAGING SERVICE MODEL
  const [action, setAction] = useState("create");
  const [serverError, setServerError] = useState(false);
  const [preloader, setPreloader] = useState(false);
  const [validationErrors, setValidationErros] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  // Staff Inputs
  const [name, setName] = useState(" ");
  const [email, setEmail] = useState(" ");
  const [password, setPassword] = useState(" ");
  const [mobile, setMobile] = useState(1);
  const [team, setTeam] = useState(" ");
  const [location, setLocation] = useState(" ");
  const [region, setRegion] = useState(" ");
  const [address, setAddress] = useState(" ");
  const [gender, setGender] = useState(" ");
  const [dob, setDob] = useState(" ");
  const [isActive, setIsActive] = useState(false);

  const [staffId, setStaffId] = useState(null);

  const handleDateChange = (event) => {
    const inputValue = event.target.value; // Value from the date input
    setDob(inputValue); // Store the input value in state
  };

  /*
|========================================================
| Add New Staff -- API CALLING
|========================================================
*/
  async function storeStaff(e) {
    e.preventDefault();

    setPreloader(true);

    // get token from local storage
    const token = localStorage.getItem("token");
    // console.log(token);

    // MAKE FORMDATA FOR CALLING STORE NEW SERVER API
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("mobile_no", mobile);
    formData.append("team", team);
    formData.append("location", location);
    formData.append("region", region);
    formData.append("address", address);
    formData.append("gender", gender);
    formData.append("date_of_birth", dob);
    formData.append("status", isActive);
    const body = formData;

    let url = "";
    // Create/Update END-POINT
    if (action == "create") {
      url = api_url + "staff/store";
    } else {
      url = api_url + "staff/" + staffId + "/update";
    }

    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    // console.log(response);
    if (response && response.status == 200) {
      setPreloader(false);

      console.log(response.data);

      let data = response.data;
      if (data.status == 200) {
        let message = data.message;
        setMessage(message);
        setSuccessMessage(true);

        // navigate("/student/services");
      } else {
        setErrors(data.errors);
        setValidationErros(true);
      }
    } else {
      console.log("API CALLING FAILED");
    }
  }

  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Staff Information
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
            </div>

            {/* print validtion errors */}
            <div className="row">
              <div className="col-md-12">
                {validationErrors ? (
                  <div
                    className="alert alert-danger alert-dismissible"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <ul className="mb-0">
                      {errors.map((error) => {
                        return (
                          <li key={error.id} className="list">
                            <span className="repo-text">{error} </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <form onSubmit={storeStaff}>
              <div className="modal-body p-4">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="name">
                        Name <sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="name"
                        name="name"
                        value={name}
                        placeholder="Name"
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="email">
                        Email <sup>*</sup>
                      </label>
                      <input
                        type="email"
                        className="form-control mb-4"
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="password">
                        Password <sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="password"
                        name="password"
                        value={password}
                        placeholder="Password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="mobile">
                        Mobile No <sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="mobile"
                        name="mobile"
                        value={mobile}
                        placeholder="Mobile No"
                        required
                        onChange={(e) => setMobile(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="gender">Gender</label>
                      <select
                        className="form-control"
                        id="gender"
                        name="gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option>Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Femail</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="dob">Date of Birth</label>
                      <input
                        type="date"
                        className="form-control mb-4"
                        id="dob"
                        name="dob"
                        value={dob}
                        onChange={handleDateChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="location">Location</label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="location"
                        name="location"
                        value={location}
                        placeholder="Location"
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="region">Region</label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="region"
                        name="region"
                        value={region}
                        placeholder="Region"
                        onChange={(e) => setRegion(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="address">Address</label>
                      <textarea
                        className="form-control mb-4"
                        id="address"
                        name="address"
                        value={address}
                        placeholder="Address"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                  <hr />

                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="image">Profile image</label>
                      <input
                        type="file"
                        className="form-control mb-4"
                        id="image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn" data-dismiss="modal">
                  <i className="flaticon-cancel-12" />
                  Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default StaffModal;

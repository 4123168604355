import StatCard from "../../components/Card/Stat/StatCard";
import { Link } from "react-router-dom";
import StaffModal from "../../components/Modal/StaffModal";

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../config";

function Staff() {
  // USE USE-STATE HOOKS TO MANAGE PAGE CONTENT
  const [actor, setActor] = useState(" ");
  let user = JSON.parse(localStorage.getItem("user"));

  const [preloader, setPreloader] = useState(true);
  const [users, setUsers] = useState([]);
  const [stats, setStats] = useState([]);

  const location = useLocation();
  const path = location.pathname;
  // console.log(path);

  const token = localStorage.getItem("token");

  useEffect(() => {
    getStaff();

    const role = localStorage.getItem("role");
    setActor(role);
  }, []);

  // get student services
  async function getStaff(e) {
    let url = api_url + "staff";

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    // console.log(response);
    if (response && response.status == 200) {
      setPreloader(false);

      let users = response.data.staff;
      let data = response.data;

    //   console.log(data);
      setUsers(users);
      setStats(data);
    } else {
      console.log("something went wrong");
    }
  }

  return (
    <>
      {!preloader ? (
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="page-header">
              <nav className="breadcrumb-one" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/staff">Staff</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    List
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row layout-spacing layout-top-spacing">
              <StatCard title="Total Staff" value={stats.staff.length} />
              <StatCard title="Active Staff" value={stats.active} />
              <StatCard title="Inactive Staff" value={stats.inactive} />
            </div>
            <div className="row layout-spacing">
              <div className="col-lg-12">
                <div className="statbox widget box box-shadow">
                  <div className="widget-content widget-content-area">
                    <h4>
                      STAFF
                      <button
                        type="button"
                        class="btn btn-primary mb-2 mr-2 float-right"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                        Add Staff
                      </button>
                    </h4>
                    <hr />

                    <table id="style-3" className="table style-3  table-hover">
                      <thead>
                        <tr>
                          <th
                            width="5%"
                            className="checkbox-column text-center"
                          >Id
                          </th>
                          <th width="5%" className="text-center">
                            Image
                          </th>
                          <th width="10%">Name</th>
                          <th>Email</th>
                          <th>Mobile No.</th>
                          <th width="10%" className="text-center">
                            Status
                          </th>
                          <th width="10%" className="text-center dt-no-sorting">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((staff, index) => {
                          return (
                            <tr key={staff.id}>
                              <td className="checkbox-column text-center">
                                {index + 1}
                              </td>
                              <td className="text-center">
                                <span>
                                  <img
                                    src="assets/img/90x90.jpg"
                                    className="profile-img"
                                    alt="avatar"
                                  />
                                </span>
                              </td>
                              <td>{staff.name}</td>
                              <td>{staff.email}</td>
                              <td>{staff.mobile_no}</td>
                              <td className="text-center">
                                <span
                                  className={`shadow-none badge ${
                                    staff.status
                                      ? "badge-success"
                                      : "badge-danger"
                                  }`}
                                >
                                  {staff.status ? "Active" : "Inactive"}
                                </span>
                              </td>
                              <td className="text-center">
                                <ul className="table-controls">
                                  <li className="px-3">
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title
                                      data-original-title="Edit"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-edit-2 p-1 br-6 mb-1"
                                      >
                                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                      </svg>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title
                                      data-original-title="Delete"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-trash p-1 br-6 mb-1"
                                      >
                                        <polyline points="3 6 5 6 21 6" />
                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                      </svg>
                                    </a>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <img
          className="d-flex mx-auto"
          style={{ marginTop: "125px" }}
          src={require("../../../src/assets/preloaders/loader2.gif")}
          width="50%"
          height="auto"
        />
      )}

      <StaffModal />
    </>
  );
}

export default Staff;

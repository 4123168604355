import React from 'react'

function ProjectModal() {
return (
<>
    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add Project Information</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        x
                    </button>
                </div>
                <div className="modal-body p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="title">Title <sup>*</sup></label>
                                <input type="text" className="form-control mb-4" id="title"
                                    placeholder="Title" />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <input type="text" className="form-control mb-4" id="description"
                                    placeholder="Description" />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="detail">Detail</label>
                                <textarea className="form-control mb-4" id="detail"
                                    placeholder="Detail" />
                            </div>
                        </div>

                        <div className="col-md-4 pr-0">
                            <div className="form-group">
                                <label htmlFor="country">Country</label>
                                <select className="form-control" id="country">
                                    <option>select country</option>
                                    <option value="male">Male</option>
                                    <option value="female">Femail</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 px-1">
                            <div className="form-group">
                                <label htmlFor="state">State</label>
                                <select className="form-control" id="state">
                                    <option>select state</option>
                                    <option value="male">Male</option>
                                    <option value="female">Femail</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 pl-0">
                            <div className="form-group">
                                <label htmlFor="city">City</label>
                                <select className="form-control" id="city">
                                    <option>select city</option>
                                    <option value="male">Male</option>
                                    <option value="female">Femail</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                        <hr />

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="image">Profile image</label>
                                <input type="file" className="form-control mb-4" id="image" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn" data-dismiss="modal"><i className="flaticon-cancel-12" />
                        Discard</button>
                    <button type="button" className="btn btn-primary">Save</button>
                </div>
            </div>
        </div>
    </div>

</>
)
}

export default ProjectModal
